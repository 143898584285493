import React from 'react';
import { InfiniteTable } from '../../../Shared/Table/InfiniteTable';
import { useDeviceState } from '../../../../hook/hooks/devices';
import { Badge, Form, Input } from 'antd';
import { Layout } from 'antd/es';
import { ASCEND, SCANNER_DEVICE_FX9600_FILTER, TABLE_EDIT_NAME, } from '../../../../constants/constants';
import { FX9600State } from '../../../../store/types/devices';
import { Columns } from '../../../Shared/Table/CustomTable/Table';
import { NavigationBar } from '../../../Shared/NavigationBar/NavigationBar';
import { API } from '../../../../settings/server.config';
import { Select } from '../../../Shared/Select/Select';
import { useFilterParams } from '../../../../hook/customHooks/customHooks';

enum FX9600Column {
  ipAddress = 'ipAddress',
  status = 'status.title',
  location = 'mapDescription',
  site = 'siteName'
}

export const FX9600 = () => {
  
  const {sites, maps} = useDeviceState();
  
  const { filter: FX9600filters, addFilter } = useFilterParams(SCANNER_DEVICE_FX9600_FILTER);
  
  const columns: Array<Columns> = [
    {
      title: 'Device Model',
      dataIndex: 'rfidType',
      key: '',
    },
    {
      title: 'Hostname',
      dataIndex: 'ipAddress',
      key: FX9600Column.ipAddress,
      sorter: true,
      defaultSortOrder: ASCEND,
    },
    {
      title: 'Status',
      key: FX9600Column.status,
      dataIndex: FX9600Column.status,
      sorter: true,
      render: (a: any, b: FX9600State) => (b.status?.title === 'Failure' ? <Badge color="red" text="Failure"/> :
        <Badge color="gray" text={b.status?.title}/>)
    },
    {
      title: 'Antenna',
      key: 'antenna',
      dataIndex: 'antenna',
      render: (a: any, b: FX9600State) => {
        return b.rfidAntennas.map(antenna => (
          <Badge color={!antenna.disabled ? 'gray' : 'green'}/>
        ));
      },
    },
    {
      title: 'Config State',
      dataIndex: 'configuration_state',
      key: 'configuration_state'
    },
    {
      title: 'Site Name',
      key: FX9600Column.site,
      dataIndex: FX9600Column.site,
      sorter: true,
    },
    {
      title: 'Map Name',
      key: FX9600Column.location,
      dataIndex: FX9600Column.location,
      sorter: true,
    }
  ];
  
  const status: Array<string> = ['Failure', 'Publishing', 'Disabled', 'Running', 'n/a', 'Rebooting', 'Initialized', 'Updating'];
  
  const partFilters: Array<any> = [
    {
      query: 'ipAddress',
      display: (
        <Form.Item className='select-label-up'>
          <Input.Search
            id="cDFX9Hostname"
            style={{width: '100%'}}
            size="small"
            defaultValue={FX9600filters['ipAddress'] ? FX9600filters['ipAddress'].params[0] : undefined}
            onSearch={(value => {
              addFilter({
                ipAddress: {
                  params: [value]
                }
              });
            })}
            placeholder="Search hostname..."
          />
        </Form.Item>
      )
    },
    {
      query: 'status',
      display: (
        <Form.Item className='select-label-up'>
          <label className='label-select'>Status</label>
          <Select
            className="filters-selector"
            selectAllValue=""
            validateValue
            id="cDFX9Status"
            showSearch
            style={{width: '100%', minWidth: '150px'}}
            size="small"
            value={FX9600filters['status'] ? FX9600filters['status'].params[0] : (status[9] || '')}
            onChange={(value) => {
              addFilter({
                status: {
                  params: [value]
                }
              });
            }}
            options={status.map(stat => ({value: stat, display: stat}))}
          />
        </Form.Item>
      )
    },
    {
      query: 'sites',
      display: (
        <Form.Item className='select-label-up'>
          <label className='label-select'>Sites</label>
          <Select
            className="filters-selector"
            selectAllValue=""
            validateValue
            id="cDFX9Sites"
            showSearch
            style={{width: '100%', minWidth: '150px'}}
            size="small"
            value={FX9600filters['siteId'] ? FX9600filters['siteId'].params[0] : undefined}
            onChange={(value) => {
              addFilter({
                siteId: {
                  params: [value]
                }
              });
            }}
            options={Object.values(sites).map(site => ({value: site._id, display: site.name}))}
          />
        </Form.Item>
      )
    },
    {
      query: 'maps',
      display: (
        <Form.Item className='select-label-up'>
          <label className='label-select'>Maps</label>
          <Select
            className="filters-selector"
            selectAllValue=""
            validateValue
            id="cDFX9Maps"
            showSearch
            style={{width: '100%', minWidth: '150px'}}
            size="small"
            value={FX9600filters?.mapID ? maps[FX9600filters?.mapID?.params[0]]?.description : undefined}
            onChange={(value) => {
              addFilter({
                mapID: {
                  params: [value + '']
                }
              });
            }}
            options={Object.values(maps).map(map => ({value: map._id, display: map.description}))}
          />
        </Form.Item>
      )
    },
  ];
  
  return (
    <Layout>
      <div className="card-container">
        <NavigationBar
          navTitle={
            <div className="navigationbar-header-configuration">
              <span className="navigation-bar-configuration">Device FX9600</span>
            </div>
          }
        />
        <InfiniteTable
          url={API.DEVICES.FX9600.FX9600_LIST_MWE()}
          fetchType="GET"
          filters={partFilters}
          columns={columns}
          componentName=""
          filterName={SCANNER_DEVICE_FX9600_FILTER}
          columnEditName={TABLE_EDIT_NAME.FX_9600_COLUMN}
          defaultFiltersObject={{
            sort: {
              params: [FX9600Column.ipAddress, 'asc']
            }
          }}
        />
      </div>
    </Layout>
  );
};
